<template>
    <div class="letsgo-container" :style="{'height': __layoutCenterHeightCalculate.value - 20 +'px'}">
        <!-- component Let's go: experiences -->
        <components-let-s-go
            :header-title="response.experienceName"
            :title="getTask.title"
            :busy-on="busyOn"
            :button-next-hidden="true"
            @eventPrevious="eventPrevious"
        >
            <!-- content: task -->
            <template #content>
                <div>
                    <!-- description -->
                    <div v-if="!__isNull(getTask.description)" class="mb-4 text-primary text-justify">
                        {{getTask.description}}
                    </div>
                    <!-- question -->
                    <div class="text-secondary text-center" style="font-weight: bold; font-size: larger">
                        {{getTask.question}}
                    </div>
                    <!-- answers -->
                    <div class="mt-2">
                        <div v-for="answer in getTask.answers">
                            <b-button
                                class="my-2"
                                :variant="getButtonsAttempts[answer.key] == 0 ? 'danger' : getButtonsAttempts[answer.key] > 0 ? 'secondary' : 'primary'"
                                @click="saveTaskSolution(answer)"
                            >{{answer.text}}
                            </b-button>
                        </div>
                    </div>
                    <!-- check answer message -->
                    <div class="text-left">
                    </div>
                </div>
            </template>
            <!-- content: help -->
            <template #help>
                <div v-html="$t('adventure.help_content.task')"></div>
            </template>
        </components-let-s-go>
    </div>
</template>

<script>
import ComponentsLetSGo from "@/components/_public/let-s-go/elements/ComponentLet-s-go";

export default {
    name: "LetsGoTask",
    components: {ComponentsLetSGo},
    props: {},
    data() {
        return {
            busyOn: false,
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                getTask: `api/v1/let-s-go/task`,
                getAnswerPoints: `api/v1/let-s-go/saveTaskSolution`,
            },
            response: [],
        }
    },
    mounted() {
        this.loadDataFromBase();
    },
    methods: {
        // <-- events -->
        eventPrevious() {
            this.$router.push(`/let-s-go`
                + '/' + this.$route.params.route
                + '/' + this.$route.params.destination
                + '/' + this.$route.params.experience
                + '/tasks'
            ).then();
        },
        // <-- base -->
        /**
         * @vuese
         * Load destinations from database
         */
        loadDataFromBase() {
            this.busyOn = true;
            this.response = [];
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getTask
                + `/${this.$route.params.route}`
                + `/${this.$route.params.experience}`
                + `/${this.$route.params.task}`,
                config).then((response) => {
                    this.$set(this, 'response', response.data);
                    this.busyOn = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
        /**
         * @vuese
         * Checking the correctness of the answer
         */
        saveTaskSolution(answer) {
            // <-- the button has already been used for an attempt -->
            if(Object.keys(this.getButtonsAttempts).includes(answer.key)){
                return;
            }
            // <-- The correct answer is already taken -->
            for (let key in this.getButtonsAttempts) {
                if(this.getButtonsAttempts[key] > 0){
                    return;
                }
            }
            // <-- save -->
            this.busyOn = true;
            this.response.taskSolutions = [];
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getAnswerPoints
                + `/${this.$route.params.route}`
                + `/${this.$route.params.experience}`
                + `/${this.$route.params.task}`
                + `/${answer.key}`, config)
                .then((response) => {
                    this.$set(this.response, 'taskSolutions', response.data);
                    this.busyOn = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
    },
    computed: {
        getTask() {
            let task = this.response.task;
            if (this.__isNull(task)) {
                return {
                    title: '',
                    description: null,
                    question: null,
                    answers: []
                }
            }
            // <--  -->
            return {
                title: this.$tc('adventure.task', 0) + ' ' + task.number + ': ' + task.title,
                description: task.description,
                question: task.question,
                answers: task.answers
            };
        },
        getButtonsAttempts(){
            let object = {};
            // <--  -->
            if(this.__isNull(this.response.taskSolutions)){
                return object;
            }
            // <--  -->
            this.response.taskSolutions.content.forEach(taskSolution => {
                object[taskSolution.answer_key] = taskSolution.points.points;
            });
            return object;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
